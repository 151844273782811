/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, SimpleGrid, Text, useEditable } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import CheckTable from "views/admin/dataTables/components/CheckTable";
import ColumnsTable from "views/doctor/dataTables/components/ColumnsTable";
import ComplexTable from "views/admin/dataTables/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/doctor/dataTables/variables/columnsData";
import tableDataDevelopment from "views/admin/dataTables/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/dataTables/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex.json";
import React, { useEffect, useContext, useState } from "react";
import Axios from "axios";
import { AuthContext } from "contexts/AuthContextProvider";
import Card from "components/card/Card";
import { BACKEND_URL } from "../../../constants.js";
import TripleCharts from "./components/TripleChart.js";

export default function Settings() {
  // state
  const [data, setData] = useState({});
  const [showChart, setShowChart] = useState(false);
  const [inputData, setInputData] = useState({});

  // context
  const { authToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(`${BACKEND_URL}/z-index/doctor/`, {
          headers: {
            Authorization: `Bearer ${authToken.access}`,
          },
        });
        const newData = data.map((item) => {
          return {
            name: item.name,
            mother: item.mother,
            father: item.father,
            gender: item.gender,
            phone_number: item.number,
            center: item.center,
            position: item.position,
            height: item.height,
            weight: item.weight,
            dob: item.dob,
            dov: item.dov,
            lfha: item.LFAZScore,
            whfl: item.WHFLZScore,
            wfa: item.WFAZScore,
            number: item.number,
            asha_name: item.asha_name,
            asha_number: item.asha_number,
            user: item.doctor,
          };
        });
        // console.log(newData);
        setData(newData);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {!showChart ? (
          <>
            {data.length > 0 ? (
              <ColumnsTable
                columnsData={columnsDataColumns}
                tableData={data}
                setShowChart={setShowChart}
                setInputData={setInputData}
              />
            ) : (
              <Card>
                <Flex
                  px="25px"
                  justify="space-between"
                  mb="20px"
                  align="center"
                >
                  <Text
                    color="black"
                    fontSize="22px"
                    fontWeight="700"
                    lineHeight="100%"
                  >
                    No Data Found...
                  </Text>
                </Flex>
              </Card>
            )}
          </>
        ) : (
          <>
            {inputData && (
              <>
                <TripleCharts
                  setShowChart={setShowChart}
                  inputData={inputData}
                />
              </>
            )}
          </>
        )}
      </SimpleGrid>
    </Box>
  );
}
