import React, { createContext, useState } from "react";

export const ExistingUserContext = createContext();

const ExistingUserContextProvider = ({ children }) => {
  const [existingUser, setExistingUser] = useState({});

  const value = {
    existingUser,
    setExistingUser,
  };

  return (
    <ExistingUserContext.Provider value={value}>
      {children}
    </ExistingUserContext.Provider>
  );
};

export default ExistingUserContextProvider;
