import React, { createContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { BACKEND_URL } from "constants";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() =>
    localStorage.getItem("authToken")
      ? JSON.parse(localStorage.getItem("authToken"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authToken")
      ? jwtDecode(localStorage.getItem("authToken"))
      : null
  );

  const [role, setRole] = useState(() =>
    localStorage.getItem("authToken")
      ? jwtDecode(localStorage.getItem("authToken")).role.toLowerCase()
      : null
  );

  const [hospital, setHospital] = useState(() =>
    localStorage.getItem("authToken")
      ? jwtDecode(localStorage.getItem("authToken")).hospital
      : null
  );

  const [loading, setLoading] = useState(true);

  const setToken = (token) => {
    setAuthToken(token);
    setUser(jwtDecode(token.access));
    setHospital(jwtDecode(token.access).hospital);
    setRole(jwtDecode(token.access).role.toLowerCase());

    localStorage.setItem("authToken", JSON.stringify(token));
  };

  const removeToken = () => {
    setAuthToken(null);
    setUser(null);
    setRole(null);
    setHospital(null);
    localStorage.removeItem("authToken");
  };

  const updateToken = async () => {
    console.log("Updating token");
    const data = {
      refresh: authToken.refresh,
    };
    try {
      const resp = await axios.post(`${BACKEND_URL}/refresh/`, data);
      if (resp.status === 200) {
        setToken(resp.data);
      } else {
        removeToken();
      }
    } catch (error) {
      console.log(error);
      removeToken();
    }

    if (loading) {
      setLoading(false);
    }
  };

  const contextData = {
    authToken,
    setAuthToken,
    user,
    setToken,
    removeToken,
    hospital,
    role,
  };

  useEffect(() => {
    if (authToken) {
      updateToken();
    }
  }, []);

  useEffect(() => {
    const time = 1000 * 60 * 4;
    if (authToken) {
      const interval = setInterval(() => {
        updateToken();
      }, time);
      return () => clearInterval(interval);
    }
  }, [authToken, loading]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
