import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import Card from "components/card/Card";
import { fields, colors } from "utils/TripleChart";

const ChartComponent = React.memo(({ data, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [opacity, setOpacity] = useState({});

  const handleMouseEnter = (o) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 1 }));
  };

  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      height="100%"
      mb="0px"
      // overflow="scroll"
      {...rest}
    >
      <Flex w="100%" h="100%" justifyContent="start">
        <Box minW="100%">
          {loading ? (
            <Spinner />
          ) : (
            <ResponsiveContainer
              width="100%"
              height="100%"
              minWidth="500px"
              minHeight="500px"
            >
              <LineChart
                width={800}
                height={500}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                {fields.map((field, index) => (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={field}
                    stroke={colors[index]}
                  />
                ))}

                <Line
                  type="monotone"
                  dataKey="Individual"
                  stroke="black"
                  fill="black"
                  r={5}
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                  connectNulls
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Flex>
    </Card>
  );
});

export default ChartComponent;
