import {
  Box,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { AuthContext } from "contexts/AuthContextProvider";
import { BACKEND_URL } from "constants";

const AllStats = ({ days }) => {
  const { authToken } = useContext(AuthContext);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.post(
          `${BACKEND_URL}/z-index/monitoring/`,
          { days: days },
          {
            headers: {
              Authorization: `Bearer ${authToken.access}`,
            },
          }
        );
        setData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Flex justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} spacing="4">
          <TableContainer>
            <Table variant="simple">
              <TableCaption>Growth Monitoring Data</TableCaption>
              <Thead>
                <Tr>
                  <Th>Title</Th>
                  <Th>Male</Th>
                  <Th>Female</Th>
                  <Th>Total</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Total Registered in Growth Monitoring</Td>
                  <Td>{data?.totalPatients?.male}</Td>
                  <Td>{data?.totalPatients?.female}</Td>
                  <Td>
                    {data?.totalPatients?.male + data?.totalPatients?.female}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Moderately Underweight</Td>
                  <Td>{data?.moderatelyUnderweight?.male}</Td>
                  <Td>{data?.moderatelyUnderweight?.female}</Td>
                  <Td>
                    {data?.moderatelyUnderweight?.male +
                      data?.moderatelyUnderweight?.female}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Severy Underweight</Td>
                  <Td>{data?.severeUnderweight?.male}</Td>
                  <Td>{data?.severeUnderweight?.female}</Td>
                  <Td>
                    {data?.severeUnderweight?.male +
                      data?.severeUnderweight?.female}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Moderately Stunting</Td>
                  <Td>{data?.moderatelyStunting?.male}</Td>
                  <Td>{data?.moderatelyStunting?.female}</Td>
                  <Td>
                    {data?.moderatelyStunting?.male +
                      data?.moderatelyStunting?.female}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Severely Stunting</Td>
                  <Td>{data?.severeStunting?.male}</Td>
                  <Td>{data?.severeStunting?.female}</Td>
                  <Td>
                    {data?.severeStunting?.male + data?.severeStunting?.female}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Moderately Wasting</Td>
                  <Td>{data?.moderatelyWasted?.male}</Td>
                  <Td>{data?.moderatelyWasted?.female}</Td>
                  <Td>
                    {data?.moderatelyWasted?.male +
                      data?.moderatelyWasted?.female}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Severely Wasting</Td>
                  <Td>{data?.severeWasted?.male}</Td>
                  <Td>{data?.severeWasted?.female}</Td>
                  <Td>
                    {data?.severeWasted?.male + data?.severeWasted?.female}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </SimpleGrid>
      )}
    </>
  );
};

export default AllStats;
