import React, { useState } from "react";
import InitialComponent from "./InitialComponent";
import NewUserComponent from "./NewUser/Main";
import ExistingUserComponent from "./ExistingUser/Main";

const BaseComponent = () => {
  const [isNewEntry, setIsNewEntry] = useState(-1);

  return isNewEntry === -1 ? (
    <InitialComponent setIsNewEntry={setIsNewEntry} />
  ) : isNewEntry == 1 ? (
    <NewUserComponent setIsNewEntry={setIsNewEntry} />
  ) : (
    <ExistingUserComponent />
  );
};

export default BaseComponent;
