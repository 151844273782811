export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "MOTHER",
    accessor: "mother",
  },
  {
    Header: "FATHER",
    accessor: "father",
  },
  {
    Header: "PHONE NUMBER",
    accessor: "phone_number",
  },
  {
    Header: "CENTER",
    accessor: "center",
  },
  {
    Header: "GENDER",
    accessor: "gender",
  },
  {
    Header: "POSITION",
    accessor: "position",
  },
  {
    Header: "HEIGHT",
    accessor: "height",
  },
  {
    Header: "WEIGHT",
    accessor: "weight",
  },
  {
    Header: "Date of Birth",
    accessor: "dob",
  },
  {
    Header: "Date of Visit",
    accessor: "dov",
  },
  {
    Header: "WHFL",
    accessor: "whfl",
  },
  {
    Header: "WFA",
    accessor: "wfa",
  },
  {
    Header: "LFHA",
    accessor: "lfha",
  },
  {
    Header: "ASHA NAME",
    accessor: "asha_name",
  },
  {
    Header: "ASHA NUMBER",
    accessor: "asha_number",
  },
  {
    Header: "DOCTOR",
    accessor: "user",
  },
];

// export const columnsDataColumns = [
//   {
//     Header: "NAME",
//     accessor: "name",
//   },
//   {
//     Header: "PROGRESS",
//     accessor: "progress",
//   },
//   {
//     Header: "QUANTITY",
//     accessor: "quantity",
//   },
//   {
//     Header: "DATE",
//     accessor: "date",
//   },
// ];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
