export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "MOTHER",
    accessor: "mother",
  },
  {
    Header: "GENDER",
    accessor: "gender",
  },
  {
    Header: "POSITION",
    accessor: "position",
  },
  {
    Header: "HEIGHT",
    accessor: "height",
  },
  {
    Header: "WEIGHT",
    accessor: "weight",
  },
  {
    Header: "DOB",
    accessor: "dob",
  },
  {
    Header: "DOV",
    accessor: "dov",
  },
  {
    Header: "Add New",
    accessor: "addNew",
  },
];
