import React, { useContext, useEffect, useRef, useState } from "react";

import Card from "components/card/Card";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import InputField from "../components/InputBox";

import { ExistingUserContext } from "contexts/ExistingUserContextProvider";
import Axios from "axios";
import { BACKEND_URL } from "constants";
import { AuthContext } from "contexts/AuthContextProvider";
import toast from "react-hot-toast";

export default function DataForm({ setShowInput, setData }) {
  const [error, setError] = useState({});

  const [position, setPosition] = useState("");
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(true);

  const babyNameRef = useRef();
  const motherNameRef = useRef();
  const fatherNameRef = useRef();
  const centerNameRef = useRef();
  const phoneNumberRef = useRef();
  const dobRef = useRef();
  const heightRef = useRef();
  const weightRef = useRef();
  const dateOfVisitRef = useRef();
  const ashaNameRef = useRef();
  const ashaNumberRef = useRef();

  const { existingUser } = useContext(ExistingUserContext);
  const { hospital } = useContext(AuthContext);

  useEffect(() => {
    const getCenters = async () => {
      try {
        const res = await Axios.post(`${BACKEND_URL}/user/all-centers/`, {
          hospital: hospital,
        });
        setCenters(res.data);
      } catch (err) {
        console.log(err);
        toast.error("There was some error fetching centers. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    getCenters();

    return () => {
      setCenters([]);
    };
  }, []);

  const calculateData = () => {
    console.log("clicked!!");
    const inputFields = [
      { ref: babyNameRef, name: "babyName" },
      { ref: motherNameRef, name: "motherName" },
      { ref: fatherNameRef, name: "fatherName" },
      { ref: centerNameRef, name: "centerName" },
      { ref: phoneNumberRef, name: "phoneNumber" },
      { ref: dobRef, name: "dob" },
      { ref: heightRef, name: "height" },
      { ref: weightRef, name: "weight" },
      { ref: dateOfVisitRef, name: "dateOfVisit" },
    ];

    const errors = {};

    inputFields.forEach((field) => {
      if (field.ref && field.ref.current && !field.ref.current.value) {
        errors[field.name] = true;
      }
    });
    if (position === "") {
      errors["position"] = true;
    }

    setError(errors);

    if (Object.keys(errors).length !== 0) {
      return;
    }

    const data = {
      babyName: babyNameRef.current.value,
      motherName: motherNameRef.current.value,
      fatherName: fatherNameRef.current.value,
      centerName: centerNameRef.current.value,
      gender: existingUser?.gender,
      phoneNumber: phoneNumberRef.current.value,
      dob: dobRef.current.value,
      height: heightRef.current.value,
      weight: weightRef.current.value,
      dateOfVisit: dateOfVisitRef.current.value,
      position: position,
      ashaName: ashaNameRef.current.value || "",
      ashaPhoneNumber: ashaNumberRef.current.value || "",
      isNew: false,
    };

    // console.log(data);
    setData(data);
    setShowInput(false);
  };

  return (
    <Card direction="column" w="100%" px="0px">
      <SimpleGrid
        mb="20px"
        columns={{ base: 1, md: 3 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <InputField
          label="Name of the Baby"
          type="text"
          ipRef={babyNameRef}
          error={error.babyName}
          value={existingUser?.name}
        />
        <InputField
          label="Name of the Mother"
          type="text"
          ipRef={motherNameRef}
          error={error.motherName}
          value={existingUser?.mother}
        />
        <InputField
          label="Name of the Father"
          type="text"
          ipRef={fatherNameRef}
          error={error.fatherName}
          value={existingUser?.father}
        />

        <Box px={{ base: "20px", md: "20px", xl: "20px" }}>
          <FormControl isInvalid={error.centerName}>
            <FormLabel>Center</FormLabel>
            <Select placeholder="Select Center" ref={centerNameRef}>
              {centers.map((center, key) => (
                <option value={center.name} key={key}>
                  {center.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box px={{ base: "20px", md: "20px", xl: "20px" }}>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <RadioGroup value={existingUser?.gender}>
              <Stack direction="row">
                <Radio value="boy">Male</Radio>
                <Radio value="girl">Female</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </Box>

        <InputField
          label="Phone Number"
          type="Number"
          ipRef={phoneNumberRef}
          error={error.phoneNumber}
          value={existingUser?.number}
        />
      </SimpleGrid>
      <HSeparator />
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 3 }}
        spacing={{ base: "20px", xl: "20px" }}
        pt={{ base: "20px", md: "20px", xl: "20px" }}
      >
        <InputField
          label="Date of Birth"
          type="date"
          ipRef={dobRef}
          error={error.dob}
          value={existingUser?.dob}
        />

        <Box px={{ base: "20px", md: "20px", xl: "20px" }}>
          <FormControl isInvalid={error.height}>
            <FormLabel>Height(cm)</FormLabel>
            <Select placeholder="Select Height" ref={heightRef}>
              {[...Array(750).keys()].map((i) => {
                const height = (45.0 + i * 0.1).toFixed(1);
                return <option value={height}>{height}</option>;
              })}
            </Select>
            {error && <FormErrorMessage>Required</FormErrorMessage>}
          </FormControl>
        </Box>

        <Box px={{ base: "20px", md: "20px", xl: "20px" }}>
          <FormControl isInvalid={error.weight}>
            <FormLabel>Weight(kg)</FormLabel>
            <Select placeholder="Select weight" ref={weightRef}>
              {[...Array(330).keys()].map((i) => {
                const weight = (1.6 + i * 0.1).toFixed(2);
                return <option value={weight}>{weight}</option>;
              })}
            </Select>
            {error && <FormErrorMessage>Required</FormErrorMessage>}
          </FormControl>
        </Box>

        {/* end */}

        <InputField
          label="Date of Visit"
          type="date"
          ipRef={dateOfVisitRef}
          error={error.dateOfVisit}
        />

        <Box px={{ base: "20px", md: "20px", xl: "20px" }}>
          <FormControl isInvalid={error.position}>
            <FormLabel>Position</FormLabel>
            <RadioGroup onChange={(e) => setPosition(e)}>
              <Stack direction="row">
                <Radio value="recumbant">Recumbant</Radio>
                <Radio value="standing">Standing</Radio>
              </Stack>
            </RadioGroup>
            {error && <FormErrorMessage>Required</FormErrorMessage>}
          </FormControl>
        </Box>
      </SimpleGrid>

      <HSeparator />

      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 3 }}
        spacing={{ base: "20px", xl: "20px" }}
        pt={{ base: "20px", md: "20px", xl: "20px" }}
      >
        <InputField label="Asha's Name" type="text" ipRef={ashaNameRef} />

        <InputField
          label="Asha's Phone Number"
          type="number"
          ipRef={ashaNumberRef}
        />
      </SimpleGrid>

      <Flex mx={{ base: "20px" }} gap={3}>
        <Button
          type="button"
          colorScheme="blue"
          variant="solid"
          // w={{ base: "20p" }}
          onClick={() => calculateData()}
        >
          Calculate
        </Button>
      </Flex>
    </Card>
  );
}
