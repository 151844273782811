import { Box } from "@chakra-ui/react";
import React, { useState } from "react";

import ExistingUserForm from "./components/ExistingUserForm";
import InputForm from "./components/InputForm";

import ExistingUserContextProvider from "contexts/ExistingUserContextProvider";

import ColumnsTable from "./dataTable/components/ColumnsTable";
import { columnsDataColumns } from "./dataTable/variables/columnsData";

const Main = () => {
  const [data, setData] = useState([]);

  const [showInput, setShowInput] = useState(false);

  return (
    <>
      <ExistingUserContextProvider>
        {!showInput ? (
          <>
            <Box mt={{ base: "130px", md: "80px", xl: "80px" }}>
              <ExistingUserForm setData={setData} />
            </Box>
            <Box mt="20px">
              {data.length > 0 && (
                <ColumnsTable
                  tableData={data}
                  columnsData={columnsDataColumns}
                  setShowInput={setShowInput}
                />
              )}
            </Box>
          </>
        ) : (
          <>
            <InputForm />
          </>
        )}
      </ExistingUserContextProvider>
    </>
  );
};

export default Main;
