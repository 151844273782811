import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";

// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import BarChart from "components/charts/BarChart";

// Custom components
import Card from "components/card/Card.js";
// import {
//   barChartDataDailyTraffic,
//   barChartOptionsDailyTraffic,
// } from "variables/charts";

// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import { AuthContext } from "contexts/AuthContextProvider";
import { BACKEND_URL } from "constants";

const barChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: [],
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#CBD5E0",
        fontSize: "14px",
      },
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: "#4318FF",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(67, 24, 255, 1)",
            opacity: 0.28,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "40px",
    },
  },
};

export default function VisitorsBarGraph(props) {
  const { ...rest } = props;

  const { authToken } = useContext(AuthContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`${BACKEND_URL}/z-index/count-centers`, {
          headers: {
            Authorization: `Bearer ${authToken.access}`,
          },
        });
        updateData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [authToken.access]);

  const updateData = (data) => {
    if (Object.keys(data).length === 0) return;

    const newData = [];

    barChartOptions.xaxis.categories = Object.keys(data);
    for (const key in data) {
      newData.push(data[key]);
    }

    const barChartData = {
      name: "Total Visits",
      data: newData,
    };
    setData([barChartData]);
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex w="100%">
            <Text
              me="auto"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              Total Visits
            </Text>
          </Flex>
          <Flex align="end">
            <Text
              color={textColor}
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              {data.length > 0 ? data[0].data.reduce((a, b) => a + b, 0) : 0}
            </Text>
            <Text
              ms="6px"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              Visits
            </Text>
          </Flex>
        </Flex>
        {/* <Flex align="center">
          <Icon as={RiArrowUpSFill} color="green.500" />
          <Text color="green.500" fontSize="sm" fontWeight="700">
            +2.45%
          </Text>
        </Flex> */}
      </Flex>
      <Box h="240px" mt="auto">
        {data.length > 0 ? (
          <BarChart chartData={data} chartOptions={barChartOptions} />
        ) : (
          <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
            <Text>No Data Yet...</Text>
          </Flex>
        )}
      </Box>
    </Card>
  );
}
