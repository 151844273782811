import React, { useState } from "react";
import { Box } from "@chakra-ui/react";

import InputDataForm from "./InputDataForm";
import TripleCharts from "components/charts/TripleCharts";

export default function Main({ setIsNewEntry }) {
  const [showInput, setShowInput] = useState(true);
  const [inputData, setInputData] = useState({});

  return (
    <Box mt={{ base: "130px", md: "80px", xl: "80px" }}>
      {showInput ? (
        <InputDataForm
          setShowInput={setShowInput}
          setData={setInputData}
          setIsNewEntry={setIsNewEntry}
        />
      ) : (
        <TripleCharts
          inputData={inputData}
          setShowInput={setShowInput}
          canSave="true"
        />
      )}
    </Box>
  );
}
