export const fields = [
  "SD4neg",
  "SD3neg",
  "SD2neg",
  "SD1neg",
  "SD0",
  "SD1",
  "SD2",
  "SD3",
  "SD4",
];

export const colors = [
  "#FF5151",
  "#FF7551",
  "#FFA351",
  "#FF51A7",
  "#FF51E2",
  "#FF51A7",
  "#FFD351",
  "#39B8FF",
  "#7551FF",
];

export function calculateZScore(y, M, S, L) {
  const x = y / M;
  const z = Math.pow(x, L);
  const z1 = (z - 1) / (L * S);
  return z1;
}
