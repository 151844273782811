/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { MdAddTask, MdCoPresent, MdBarChart, MdFileCopy } from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import VisitorBarGraph from "views/admin/default/components/AllCentersBarGraph";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { BACKEND_URL } from "constants";
import { AuthContext } from "contexts/AuthContextProvider";
import Card from "components/card/Card";
import GrowthMonitoring from "./components/GrowthMonitoring/GrowthMonitoring";

export default function UserReports() {
  // context
  const { authToken } = useContext(AuthContext);

  // state
  const [analytics, setAnalytics] = useState({});

  // useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`${BACKEND_URL}/z-index/analytics/`, {
          headers: {
            Authorization: `Bearer ${authToken.access}`,
          },
        });
        setAnalytics(res.data);
      } catch (e) {
        console.log(e.response);
      }
    };

    fetchData();
  }, []);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          // startContent={
          //   <IconBox
          //     w="56px"
          //     h="56px"
          //     bg={boxBg}
          //     icon={
          //       <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
          //     }
          //   />
          // }
          name="Total Doctors"
          value={analytics.totalDoctors}
        />

        <MiniStatistics
          name="Unique Patients"
          value={analytics.uniquePatients}
        />

        <MiniStatistics
          // startContent={
          //   <IconBox
          //     w="56px"
          //     h="56px"
          //     bg={boxBg}
          //     icon={
          //       <Icon w="32px" h="32px" as={MdCoPresent} color={brandColor} />
          //     }
          //   />
          // }
          name="Total Visits"
          value={analytics.totalPatients}
        />

        {/* <MiniStatistics growth="+23%" name="Sales" value="$574.34" />
        <MiniStatistics
          // startContent={
          //   <IconBox
          //     w="56px"
          //     h="56px"
          //     bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
          //     icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
          //   />
          // }
          name="New Tasks"
          value="154"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name="Total Projects"
          value="2935"
        /> */}
      </SimpleGrid>

      {/* <SimpleGrid columns={{ base: 1 }} gap="20px" mb="20px">
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid> */}
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <GrowthMonitoring />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <VisitorBarGraph />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px">
        {/* <Tasks /> */}
        {/* <MiniCalendar h="100%" minW="100%" selectRange={false} /> */}
      </SimpleGrid>
    </Box>
  );
}
