import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdBarChart, MdPerson, MdHome, MdLock, MdInput } from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTablesAdmin from "views/admin/dataTables";
import DataTablesDoctor from "views/doctor/dataTables";
import DataTablesHospital from "views/hospital/dataTables";
import Input from "views/doctor/Input";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Admin Data Table",
    layout: "/admin",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    path: "/admin-data-tables",
    component: DataTablesAdmin,
  },
  {
    name: "Input",
    layout: "/doctor",
    path: "/input",
    icon: <Icon as={MdInput} width="20px" height="20px" color="inherit" />,
    component: Input,
  },
  {
    name: "Data Tables",
    layout: "/doctor",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/data-tables",
    component: DataTablesDoctor,
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    name: "Hospital Data Tables",
    layout: "/hospital",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/hospital-data-tables",
    component: DataTablesHospital,
  },
];

export default routes;
