// Chakra imports
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import ColumnsTable from "views/admin/dataTables/components/ColumnsTable";
import { columnsDataColumns } from "views/admin/dataTables/variables/columnsData";
import React, { useEffect, useContext, useState } from "react";
import Axios from "axios";
import { AuthContext } from "contexts/AuthContextProvider";
import Card from "components/card/Card";
import { BACKEND_URL } from "../../../constants.js";
import TripleChart from "views/doctor/dataTables/components/TripleChart.js";

export default function Settings() {
  // state
  const [data, setData] = useState({});
  const [showChart, setShowChart] = useState(false);
  const [inputData, setInputData] = useState({});

  // context
  const { authToken, hospital } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.post(
          `${BACKEND_URL}/z-index/data-by-hospital/`,
          { hospital: hospital },
          {
            headers: {
              Authorization: `Bearer ${authToken.access}`,
            },
          }
        );
        const newData = data.map((item) => {
          return {
            name: item.name,
            mother: item.mother,
            father: item.father,
            gender: item.gender,
            phone_number: item.number,
            center: item.center,
            position: item.position,
            height: item.height,
            weight: item.weight,
            dob: item.dob,
            dov: item.dov,
            lfha: item.LFAZScore,
            whfl: item.WHFLZScore,
            wfa: item.WFAZScore,
            number: item.number,
            asha_name: item.asha_name,
            asha_number: item.asha_number,
            user: item.doctor,
          };
        });
        setData(newData);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();

    return () => {
      setData({});
    };
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {!showChart ? (
          <>
            {data.length > 0 ? (
              <ColumnsTable
                columnsData={columnsDataColumns}
                tableData={data}
                setShowChart={setShowChart}
                setInputData={setInputData}
              />
            ) : (
              <Card>
                <Flex
                  px="25px"
                  justify="space-between"
                  mb="20px"
                  align="center"
                >
                  <Text
                    color="black"
                    fontSize="22px"
                    fontWeight="700"
                    lineHeight="100%"
                  >
                    No Data Found...
                  </Text>
                </Flex>
              </Card>
            )}
          </>
        ) : (
          <>
            {inputData && (
              <>
                <TripleChart
                  setShowChart={setShowChart}
                  inputData={inputData}
                />
              </>
            )}
          </>
        )}
      </SimpleGrid>
    </Box>
  );
}
