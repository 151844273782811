import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import DoctorLayout from "layouts/doctor";
import HospitalLayout from "layouts/hospital";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import AuthContextProvider from "contexts/AuthContextProvider";
import { Toaster } from "react-hot-toast";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    {/* <React.StrictMode> */}
    <ThemeEditorProvider>
      <AuthContextProvider>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/doctor`} component={DoctorLayout} />
            <Route path={`/hospital`} component={HospitalLayout} />
            <Redirect from="/" to="/admin" />
          </Switch>
        </HashRouter>
        <Toaster />
      </AuthContextProvider>
    </ThemeEditorProvider>
    {/* </React.StrictMode> */}
  </ChakraProvider>,
  document.getElementById("root")
);
