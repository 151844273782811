import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React from "react";

export default function inputFiled({
  label,
  type,
  ipRef,
  error = null,
  value = null,
}) {
  return (
    <Box px={{ base: "20px", md: "20px", xl: "20px" }}>
      <FormControl isInvalid={error}>
        <FormLabel>{label}</FormLabel>
        <Input
          type={type}
          placeholder={label.toLowerCase()}
          w={{ base: "100%", md: "100%", xl: "100%" }}
          ref={ipRef}
          value={value}
          readOnly={value ? true : false}
        />
        {error && <FormErrorMessage>Required</FormErrorMessage>}
      </FormControl>
    </Box>
  );
}
