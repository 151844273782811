// Chakra imports
import {
  Box,
  Flex,
  Text,
  Select,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import Axios from "axios";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
// import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import { BACKEND_URL } from "constants";
import { AuthContext } from "contexts/AuthContextProvider";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

const pieChartOptions = {
  labels: ["Male", "Female"],
  colors: ["#4318FF", "#6AD2FF"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#4318FF", "#6AD2FF"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export default function PieCard(props) {
  const { ...rest } = props;

  const { authToken } = useContext(AuthContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`${BACKEND_URL}/z-index/count-gender`, {
          headers: {
            Authorization: `Bearer ${authToken.access}`,
          },
        });

        updateData(res.data);
      } catch (error) {
        toast.error("Failed to fetch data");
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const updateData = (data) => {
    setData([data.male, data.female]);
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Gender Distribution
        </Text>
        {/* <Select
          fontSize='sm'
          variant='subtle'
          defaultValue='monthly'
          width='unset'
          fontWeight='700'>
          <option value='daily'>Daily</option>
          <option value='monthly'>Monthly</option>
          <option value='yearly'>Yearly</option>
        </Select> */}
      </Flex>
      {data.length > 0 ? (
        <PieChart
          h="100%"
          w="100%"
          chartData={data}
          chartOptions={pieChartOptions}
        />
      ) : (
        <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      )}
      <Card
        bg={cardColor}
        flexDirection="row"
        justifyContent="center"
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
      >
        <Flex direction="column" py="5px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="brand.500" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              Male
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {((data[0] / (data[0] + data[1])) * 100).toFixed(2)}%{" "}
          </Text>
        </Flex>
        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex direction="column" py="5px" me="10px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="#6AD2FF" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              Female
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {((data[1] / (data[0] + data[1])) * 100).toFixed(2)}%{" "}
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
