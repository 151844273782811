import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";

import { BACKEND_URL } from "constants.js";
import { AuthContext } from "contexts/AuthContextProvider";
import toast from "react-hot-toast";

import { fields, colors } from "utils/TripleChart";
import ChartComponent from "components/charts/ChartComponent";

const TripleChart = ({ setShowChart, inputData }) => {
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const [LHFAChartData, setLHFAChartData] = useState({});
  const [WFAChartData, setWFAChartData] = useState({});
  const [WFHLChartData, setWFHLChartData] = useState({});
  const [individualData, setIndividualData] = useState([]);

  const [lhfaData, setLhfaData] = useState([]);
  const [wfaData, setWfaData] = useState([]);
  const [whflData, setWhflData] = useState([]);

  const { authToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      const gender = inputData.gender;
      const height = inputData.height;
      const dateOfVisit = new Date(inputData.dov);
      const dob = new Date(inputData.dob);
      const days = Math.floor(
        (dateOfVisit.getTime() - dob.getTime()) / (1000 * 3600 * 24)
      );

      try {
        const input = {
          Gender: gender,
          Length: height,
          Days: days,
          Name: inputData.name,
          Number: inputData.number,
        };

        const { data } = await Axios.post(`${BACKEND_URL}/data/`, input, {
          headers: {
            Authorization: `Bearer ${authToken.access}`,
          },
        });

        const ip = {
          name: inputData.name,
          number: inputData.number,
        };

        const { data: individualData } = await Axios.post(
          `${BACKEND_URL}/z-index/individual/`,
          ip,
          {
            headers: {
              Authorization: `Bearer ${authToken.access}`,
            },
          }
        );

        individualData.forEach((item) => {
          item.days = Math.floor(
            (new Date(item.dov).getTime() - new Date(item.dob).getTime()) /
              (1000 * 3600 * 24)
          );
        });

        setIndividualData(individualData);

        setInitialData(data);
      } catch (e) {
        console.log(e.response);
        toast.error("Error fetching data");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (initialData.allData) {
      setWFHLChartData(configureData(initialData.allData, true, false));
    }
    if (initialData.lhfaData) {
      setLHFAChartData(configureData(initialData.lhfaData, false, true));
    }
    if (initialData.wfaData) {
      setWFAChartData(configureData(initialData.wfaData, false, false));
    }
  }, [initialData]);

  const configureData = (data, isWhfa, isLFA) => {
    const weight = inputData.weight;
    const length = inputData.height;
    const dateOfVisit = new Date(inputData.dov);
    const dob = new Date(inputData.dob);
    const days = Math.floor(
      (dateOfVisit.getTime() - dob.getTime()) / (1000 * 3600 * 24)
    );

    const dataset = [];

    data.forEach((item) => {
      const obj = {};
      fields.map((field) => {
        obj[field] = item[field];
      });
      dataset.push({
        name: isWhfa ? item.Length : item.Day,
        ...obj,
        position: isWhfa ? item.Position : "N/A",
      });
    });

    if (individualData && individualData.length > 0) {
      individualData.forEach((item) => {
        dataset.find((element) => {
          if (!isWhfa && element.name === item.days) {
            element["Individual"] = !isLFA ? item.weight : item.height;
          } else if (
            isWhfa &&
            element.name === item.height &&
            element.position.toLowerCase() === item.position.toLowerCase()
          ) {
            element["Individual"] = item.weight;
          }
        });
      });
    }

    return dataset;
  };

  return (
    <>
      <Card w={{ base: "200%", md: "100%" }}>
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          onChange={(index) => setTabIndex(index)}
        >
          <Flex>
            <TabList>
              <Tab>Length/Height For Age</Tab>
              <Tab>Weight For Age</Tab>
              <Tab>Weight For Length/Height</Tab>
            </TabList>
          </Flex>
          <TabPanels>
            <TabPanel width="100%" height="100%">
              <ChartComponent data={LHFAChartData} title="LFHA" />
            </TabPanel>
            <TabPanel width="100%" height="100%">
              <ChartComponent data={WFAChartData} title="WFA" />
            </TabPanel>
            <TabPanel width="100%" height="100%">
              <ChartComponent data={WFHLChartData} title="WHFL" />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Flex mt={2} ms={10} gap={5}>
          <Button
            colorScheme="red"
            size="md"
            onClick={() => setShowChart(false)}
            width={{ base: "10%" }}
          >
            Back
          </Button>
        </Flex>
      </Card>
    </>
  );
};

export default TripleChart;
