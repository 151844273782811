import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import ChartComponent from "./ChartComponent";

const TripleChart = (props) => {
  const {
    setTabIndex = null,
    zIndex,
    LHFAChartData,
    WFAChartData,
    WFHLChartData,
    saveData,
    canSave,
    setShowInput,
    disableSave,
  } = props;

  return (
    <>
      <Card w={{ base: "200%", md: "100%" }}>
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          onChange={(index) => setTabIndex(index)}
        >
          <Flex>
            <TabList>
              <Tab>Length/Height For Age</Tab>
              <Tab>Weight For Age</Tab>
              <Tab>Weight For Length/Height</Tab>
            </TabList>

            <Box
              ml="auto"
              mr={{ base: "0", lg: "10" }}
              h="100%"
              px={{ base: "1", lg: "3" }}
              py={{ base: "1", lg: "2" }}
              borderRadius={{ base: "0", lg: "10" }}
              backgroundColor="gray.200"
            >
              Z-Index : {zIndex}
            </Box>
          </Flex>
          <TabPanels>
            <TabPanel width="100%">
              <ChartComponent data={LHFAChartData} />
            </TabPanel>
            <TabPanel width="100%">
              <ChartComponent data={WFAChartData} />
            </TabPanel>
            <TabPanel width="100%">
              <ChartComponent data={WFHLChartData} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Flex mt={2} ms={10} gap={5}>
          <Button
            colorScheme="red"
            size="md"
            onClick={() => setShowInput(true)}
            width={{ base: "100%", sm: "10%" }}
          >
            Back
          </Button>
          {!disableSave && canSave && (
            <Button
              colorScheme="green"
              size="md"
              width={{ base: "100%", sm: "10%" }}
              onClick={saveData}
              // isLoading={loading}
            >
              Save
            </Button>
          )}
        </Flex>
      </Card>
    </>
  );
};

export default TripleChart;
