import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

export default function InputFiled({ label, type, ipRef, error = null }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box px={{ base: "20px", md: "20px", xl: "20px" }}>
      <FormControl isInvalid={error}>
        <FormLabel>{label}</FormLabel>
        <Input
          type={type}
          placeholder={label.toLowerCase()}
          w={{ base: "100%", md: "100%", xl: "100%" }}
          color={textColor}
          _placeholder={{ color: textColor }}
          ref={ipRef}
        />
        {error && <FormErrorMessage>Required</FormErrorMessage>}
      </FormControl>
    </Box>
  );
}
