import React, { useState, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";

// Chakra imports
import { Box, useColorModeValue } from "@chakra-ui/react";

// Layout components
import { SidebarContext } from "contexts/SidebarContext";
import { AuthContext } from "contexts/AuthContextProvider";
import SignInCentered from "views/auth/signIn";

// Custom Chakra theme
export default function Auth() {
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);

  // context
  const { user, role } = useContext(AuthContext);

  // functions for changing the states from components
  const authBg = useColorModeValue("white", "navy.900");

  if (user && role === "doctor") {
    return <Redirect to="/doctor/input" />;
  } else if (user && role === "admin") {
    return <Redirect to="/admin/dashboard" />;
  } else if (user && role === "hospital") {
    return <Redirect to="/hospital" />;
  }

  return (
    <Box>
      <Box
        bg={authBg}
        float="right"
        minHeight="100vh"
        height="100%"
        position="relative"
        w="100%"
        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        transitionDuration=".2s, .2s, .35s"
        transitionProperty="top, bottom, width"
        transitionTimingFunction="linear, linear, ease"
      >
        <Box mx="auto" minH="100vh">
          <Switch>
            <Route path="/auth/sign-in" component={SignInCentered} />
            <Redirect
              from="/auth"
              to="/auth/sign-in/
                  "
            />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
}
