import React, { useState } from "react";

import Main from "./NewUser/Main";
import Card from "components/card/Card";
import { Box, Button, Flex, Image } from "@chakra-ui/react";
import doctorPng from "assets/img/input/doctor.svg";

const InitialComponent = ({ setIsNewEntry }) => {
  return (
    <Box mt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Box h={{ base: "70vh" }}>
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={{
              base: "center",
              md: "space-between",
              xl: "center",
            }}
            gap={{ base: "2em", md: "0" }}
            h="100%"
            // alignItems="center"
          >
            <Flex justifyContent="start">
              <Image
                src={doctorPng}
                alt="doctor"
                width={{ base: "100%", md: "30em" }}
              />
            </Flex>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              w={{ base: "100%", lg: "50%" }}
              //   h={{ base: "100%", lg: "50%" }}
              mt={{ base: "1em", lg: "0" }}
            >
              <Box fontSize="2xl" fontWeight="bold" textAlign="center" mb="1em">
                This is Your <span style={{ color: "#3B82F6" }}>Dashboard</span>
                ,
                <br />
                Do you want to input new data or use existing data?
              </Box>
              <Flex gap="5">
                <Button
                  colorScheme="blue"
                  w="24"
                  size="md"
                  borderRadius={10}
                  onClick={() => {
                    setIsNewEntry(1);
                  }}
                >
                  New
                </Button>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  w="24"
                  borderRadius={10}
                  size="md"
                  onClick={() => {
                    setIsNewEntry(0);
                  }}
                >
                  Existing
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Card>
    </Box>
  );
};

export default InitialComponent;
