import { Box, Button, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useContext, useRef, useState } from "react";
import Axios from "axios";

import InputField from "../../InputField";
import { BACKEND_URL } from "../../../../../../constants";
import { AuthContext } from "contexts/AuthContextProvider";
import toast from "react-hot-toast";

const ExistingUserForm = ({ setData }) => {
  // state
  const [loading, setLoading] = useState(false);

  // refs
  const phoneNumber = useRef();

  // context
  const { authToken } = useContext(AuthContext);

  const getUser = async (data) => {
    try {
      const res = await Axios.post(`${BACKEND_URL}/z-index/existing/`, data, {
        headers: {
          Authorization: `Bearer ${authToken.access}`,
        },
      });
      if (res.data.length === 0) {
        return null;
      }
      return res.data;
    } catch (e) {
      console.log(e.response);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneNumber.current.value) {
      toast.error("Please fill all the fields");
      return;
    }

    setLoading(true);
    const data = {
      data: phoneNumber.current.value,
    };

    const users = await getUser(data);
    setLoading(false);
    if (!users) {
      toast.error("User not found");
      return;
    }
    const filteredData = filterUniqueData(users);
    setData(filteredData);
  };

  const filterUniqueData = (data) => {
    const uniqueData = [];
    data.forEach((item) => {
      if (!uniqueData.some((i) => i.name === item.name)) {
        uniqueData.push(item);
      }
    });
    return uniqueData;
  };

  return (
    <Card direction="column" w="100%" px="0px">
      <form onSubmit={handleSubmit}>
        <Box>
          <SimpleGrid
            mb="20px"
            columns={{ base: 1, md: 3 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <InputField
              label="Phone Number or Unique ID"
              type="text"
              ipRef={phoneNumber}
            />
            <Box mt="auto" ms={{ base: "5", md: 0 }}>
              <Button
                type="submit"
                colorScheme="blue"
                variant="solid"
                size="md"
                borderRadius="5"
                isLoading={loading}
              >
                check
              </Button>
            </Box>
          </SimpleGrid>
        </Box>
      </form>
    </Card>
  );
};

export default ExistingUserForm;
