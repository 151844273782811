import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AllStats from "./AllStats";
import Card from "components/card/Card";

const GrowthMonitoring = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <Card>
        <Tabs
          position="relative"
          variant="unstyled"
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab _focus={{ outline: "none" }}>Today</Tab>
            <Tab _focus={{ outline: "none" }}>This Week</Tab>
            <Tab _focus={{ outline: "none" }}>This Month</Tab>
            <Tab _focus={{ outline: "none" }}>All Time</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />

          <TabPanels>
            <TabPanel>
              <AllStats days={0} />
            </TabPanel>
            <TabPanel>
              <AllStats days={7} />
            </TabPanel>
            <TabPanel>
              <AllStats days={30} />
            </TabPanel>
            <TabPanel>
              <AllStats days="all" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </>
  );
};

export default GrowthMonitoring;
