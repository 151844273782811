import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Box,
  Button,
} from "@chakra-ui/react";
import ChartComponent from "./ChartComponent";
import "assets/css/TripleCharts.css";
import toast from "react-hot-toast";
import { AuthContext } from "contexts/AuthContextProvider";
import Card from "components/card/Card";
import { BACKEND_URL } from "../../constants";
import TripleChart from "./TripleChart";
import { calculateZScore } from "utils/TripleChart";
import { fields } from "utils/TripleChart";
import { colors } from "utils/TripleChart";

function TripleCharts({ inputData, setShowInput, canSave = false }) {
  // console.log(inputData);
  // state
  const [initialData, setInitialData] = useState({});
  const [WFHLChartData, setWFHLChartData] = useState({});
  const [LHFAChartData, setLHFAChartData] = useState({});
  const [WFAChartData, setWFAChartData] = useState({});

  const [individualData, setIndividualData] = useState({});

  const [WFHLZIndex, setWFHLZIndex] = useState(0);
  const [LHFAZIndex, setLHFAZIndex] = useState(0);
  const [WFAZIndex, setWFAZIndex] = useState(0);

  const [zIndex, setZIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [disableSave, setDisableSave] = useState(false);

  const [loading, setLoading] = useState(false);

  // context
  const { authToken, hospital } = useContext(AuthContext);

  Axios.defaults.headers.common["Authorization"] = `Bearer ${authToken.access}`;

  useEffect(() => {
    const fetchData = async () => {
      const gender = inputData.gender;
      const height = inputData.height;
      const dateOfVisit = new Date(inputData.dateOfVisit);
      const dob = new Date(inputData.dob);
      const days = Math.floor(
        (dateOfVisit.getTime() - dob.getTime()) / (1000 * 3600 * 24)
      );

      try {
        const input = {
          Gender: gender,
          Length: height,
          Days: days,
          Name: inputData.babyName,
          Number: inputData.phoneNumber,
        };
        const ip = {
          name: inputData.babyName,
          number: inputData.phoneNumber,
        };

        const [{ data }, { data: individualData }] = await Promise.all([
          Axios.post(`${BACKEND_URL}/data/`, input),
          Axios.post(`${BACKEND_URL}/z-index/individual/`, ip),
        ]);

        if (inputData.isNew && individualData.length !== 0) {
          setDisableSave(true);
          toast.error("Data already exists for this baby");
          return;
        }

        individualData.forEach((item) => {
          item.days = Math.floor(
            (new Date(item.dov).getTime() - new Date(item.dob).getTime()) /
              (1000 * 3600 * 24)
          );
        });

        setIndividualData(individualData);
        setInitialData(data);
      } catch (e) {
        // Handle error
        console.log(e);
        setDisableSave(true);
        toast.error("Error fetching data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (initialData.allData) {
      setWFHLChartData(configureData(initialData.allData, true, false));
    }
    if (initialData.lhfaData) {
      setLHFAChartData(configureData(initialData.lhfaData, false, true));
    }
    if (initialData.wfaData) {
      setWFAChartData(configureData(initialData.wfaData, false, false));
    }
  }, [initialData]);

  const configureData = (data, isWhfa, isLFA) => {
    const weight = inputData.weight;
    const length = inputData.height;
    const dateOfVisit = new Date(inputData.dateOfVisit);
    const dob = new Date(inputData.dob);
    const days = Math.floor(
      (dateOfVisit.getTime() - dob.getTime()) / (1000 * 3600 * 24)
    );

    const dataset = [];

    data.forEach((item) => {
      const obj = {};
      fields.map((field) => {
        obj[field] = item[field];
      });
      dataset.push({
        name: isWhfa ? item.Length : item.Day,
        ...obj,
        position: isWhfa ? item.Position : "N/A",
      });
    });

    if (individualData && individualData.length > 0) {
      individualData.forEach((item) => {
        dataset.find((element) => {
          if (!isWhfa && element.name === item.days) {
            element["Individual"] = !isLFA ? item.weight : item.height;
          } else if (
            isWhfa &&
            element.name === item.height &&
            element.position.toLowerCase() === item.position.toLowerCase()
          ) {
            element["Individual"] = item.weight;
          }
        });
      });
    }

    dataset.find((element) => {
      if (isWhfa) {
        if (
          element.name === parseFloat(length) &&
          element.position.toLowerCase() === inputData.position.toLowerCase()
        ) {
          element["Individual"] = weight;
        }
      } else {
        if (element.name === days) {
          element["Individual"] = !isLFA ? weight : length;
        }
      }
    });

    return dataset;
  };

  useEffect(() => {
    setAllZScore();
    if (tabIndex === 0) setZIndex(LHFAZIndex);
    if (tabIndex === 1) setZIndex(WFAZIndex);
    if (tabIndex === 2) setZIndex(WFHLZIndex);
  }, [
    tabIndex,
    LHFAZIndex,
    WFAZIndex,
    WFHLZIndex,
    WFHLChartData,
    LHFAChartData,
    WFAChartData,
  ]);

  const setAllZScore = () => {
    const length = inputData.height;
    const weight = inputData.weight;
    const dateOfVisit = new Date(inputData.dateOfVisit);
    const dob = new Date(inputData.dob);
    const days = Math.floor(
      (dateOfVisit.getTime() - dob.getTime()) / (1000 * 3600 * 24)
    );

    if (initialData.allData && initialData.allData.length > 0) {
      const closest = findClosest(initialData.allData, 2, length, weight);
      setWFHLZIndex(
        calculateZScore(weight, closest.M, closest.S, closest.L).toFixed(2)
      );
    }
    if (initialData.lhfaData && initialData.lhfaData.length > 0) {
      const closest = findClosest(initialData.lhfaData, 0, length, days);
      setLHFAZIndex(
        calculateZScore(length, closest.M, closest.S, closest.L).toFixed(2)
      );
    }
    if (initialData.wfaData && initialData.wfaData.length > 0) {
      const closest = findClosest(initialData.wfaData, 1, length, days);
      setWFAZIndex(
        calculateZScore(weight, closest.M, closest.S, closest.L).toFixed(2)
      );
    }
  };

  function findClosest(data, index, length, days) {
    let closest = data[0];
    data.forEach((item) => {
      if (index === 2) {
        if (
          Math.abs(item.Length - length) < Math.abs(closest.Length - length)
        ) {
          closest = item;
        }
      } else {
        if (Math.abs(item.Day - days) < Math.abs(closest.Day - days)) {
          closest = item;
        }
      }
    });
    return closest;
  }

  const saveData = async () => {
    setLoading(true);
    const data = {
      name: inputData.babyName,
      mother: inputData.motherName,
      father: inputData.fatherName,
      center: inputData.centerName,
      number: inputData.phoneNumber,
      gender: inputData.gender,
      dob: inputData.dob,
      dov: inputData.dateOfVisit,
      position: inputData.position,
      height: inputData.height,
      weight: inputData.weight,
      asha_name: inputData.ashaName,
      asha_number: inputData.ashaPhoneNumber,
      LFAZScore: LHFAZIndex,
      WFAZScore: WFAZIndex,
      WHFLZScore: WFHLZIndex,
      hospital: hospital,
      is_new: inputData.isNew,
    };
    // console.log(data);

    try {
      const res = await Axios.post(`${BACKEND_URL}/z-index/create/`, data, {
        headers: {
          Authorization: `Bearer ${authToken.access}`,
        },
      });
      toast.success("Data saved successfully");
      window.location.href = "/doctor";
    } catch (e) {
      // Handle error
      console.log(e);
      toast.error("Error saving data");
    } finally {
      setLoading(false);
    }
  };

  return (
    <TripleChart
      setTabIndex={setTabIndex}
      zIndex={zIndex}
      LHFAChartData={LHFAChartData}
      WFAChartData={WFAChartData}
      WFHLChartData={WFHLChartData}
      saveData={saveData}
      canSave={canSave}
      setShowInput={setShowInput}
      disableSave={disableSave}
    />
  );
}

export default TripleCharts;
